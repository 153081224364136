.post__meta {
  font-size: $meta-font-size;
  color: $meta-color;
  font-weight: $meta-font-weight;
}

.post__category {
  font-weight: bold;
}

.post__tag {
  background: $tag-background-color;
  border-radius: 2px;
  color: $tag-color;
  font-size: $tag-font-size;
  font-weight: bold;
  padding: 2px 6px;
}

.post__title {
  background-image: -webkit-linear-gradient(left, $gradient-color-1, $gradient-color-2); /* For Chrome and Safari */
  background-image:    -moz-linear-gradient(left, $gradient-color-1, $gradient-color-2); /* For old Fx (3.6 to 15) */
  background-image:     -ms-linear-gradient(left, $gradient-color-1, $gradient-color-2); /* For pre-releases of IE 10*/
  background-image:      -o-linear-gradient(left, $gradient-color-1, $gradient-color-2); /* For old Opera (11.1 to 12.0) */
  background-image:         linear-gradient(to right, $gradient-color-1, $gradient-color-2); /* Standard syntax; must be last */
  color:transparent;
  -webkit-background-clip: text;
  background-clip: text; 
 }
 
.post__subtitle {
  display: block;
  font-size: $post__subtitle-font-size;
  font-style: italic;
  padding: 0 0 1rem 0;
}

#references {
  li {
    font-size: 0.9rem;
  }
}
